import './src/styles/style.css';

function readLinks(item) {
  const storedSlugs = localStorage.getItem('storage:slugs');
  if (storedSlugs) {
    const slugPointers = new Map(JSON.parse(storedSlugs));
    return slugPointers.get(item);
  } else {
    return { previous: '#', next: '#' }      
  }
}

export function onInitialClientRender() {
 
  // update links
  const previousEl = document.getElementById('galleryLinkPrevious');
  const nextEl = document.getElementById('galleryLinkNext');

  if (previousEl && nextEl) {
    // what's our current url? 
    const thisArtwork = window.location.pathname.split('/').filter(e => e)[0];     

    if (thisArtwork) {
      const links = readLinks(thisArtwork);
      
      if (links) {
        previousEl.href = '/' + links.previous;
        nextEl.href = '/' + links.next;  
      } else {
        previousEl.style.display = 'none';
        nextEl.style.display = 'none';
      }
    }
  }
}


