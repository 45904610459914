exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-botanical-paintings-js": () => import("./../../../src/pages/botanical-paintings.js" /* webpackChunkName: "component---src-pages-botanical-paintings-js" */),
  "component---src-pages-contentful-painting-slug-js": () => import("./../../../src/pages/{contentfulPainting.slug}.js" /* webpackChunkName: "component---src-pages-contentful-painting-slug-js" */),
  "component---src-pages-contentful-sculpture-slug-js": () => import("./../../../src/pages/{contentfulSculpture.slug}.js" /* webpackChunkName: "component---src-pages-contentful-sculpture-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paintings-js": () => import("./../../../src/pages/paintings.js" /* webpackChunkName: "component---src-pages-paintings-js" */),
  "component---src-pages-sculptures-js": () => import("./../../../src/pages/sculptures.js" /* webpackChunkName: "component---src-pages-sculptures-js" */),
  "component---src-pages-seascapes-js": () => import("./../../../src/pages/seascapes.js" /* webpackChunkName: "component---src-pages-seascapes-js" */)
}

